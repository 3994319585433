import React from "react";
import "./Surrounding.scss";
import clubhouse from "./fasilitascl/Frame 110.png";
import joggingtrack from "./fasilitascl/Frame 111.png";
import civiccenter from "./fasilitascl/Frame 112.png";
import breeze from "./fasilitascl/Frame 113.png";
import qbig from "./fasilitascl/Frame 114.png";
import eka from "./fasilitascl/Frame 115.png";
import hospital from "./fasilitascl/Frame 118.png";
import hotel from "./fasilitascl/Frame 117.png";
import shop from "./fasilitascl/Frame 116.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Surrounding = () => {
  const slides = [
    { src: clubhouse, des: "Club House" },
    { src: joggingtrack, des: "Jogging Track" },
    { src: civiccenter, des: "Civic Center" },
    { src: breeze, des: "Bike Lane" },
    { src: qbig, des: "Playground" },
    { src: eka, des: "View Danau" },
    { src: shop, des: "Shophouse" },
    { src: hotel, des: "Mall & Hotel" },
    { src: hospital, des: "Ciputra Hospital" },
  ];
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="surroundingcontainer">
      <div className="title">FASILITAS PREMIUM</div>
      <div className="content">
        {slides.map((slide, index) => (
          <div className="center" key={index}>
            <img className="imgcontent" src={slide.src} alt="Surrounding" />
            <div className="imgdes">
              <span>{slide.des}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="content1">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div className="center" key={index}>
              <img className="imgcontent" src={slide.src} alt="Surrounding" />
              <div className="imgdes">
                <span>{slide.des}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Surrounding;
