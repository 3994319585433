import React from "react";
import maps from "./lokasicbd.png";
import "./lokasi.scss";

const Lokasicbd = () => {
  return (
    <div className="lokasi">
      <div className="containerlokasi">
        <div className="header">LOKASI</div>
        <div className="image">
          <img src={maps} alt="maps" />
        </div>
      </div>
    </div>
  );
};

export default Lokasicbd;
