import React, { useState, useEffect } from "react";
import "./navbar.scss";
import Logo from "../../asset/logo-w.webp";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { FaBars } from "react-icons/fa";
import { RiCloseLine } from "react-icons/ri";

import { NavLink } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="container-navbar">
        <div className="logo">
          <HashLink smooth to="/">
            <img src={Logo} alt="logo" />
          </HashLink>
        </div>
        <div className={isOpen ? "menus active" : "menus"}>
          <ul>
            <li>
              <NavHashLink smooth to="/">
                Beranda
                <hr />
              </NavHashLink>
            </li>
            <li>
              <NavHashLink smooth to="/citraland-cibubur">
                Citraland Cibubur
                <hr />
              </NavHashLink>
            </li>
            <li>
              <NavHashLink smooth to="/citragrand-cbd">
                CitraGrand CBD
                <hr />
              </NavHashLink>
            </li>
            <li>
              <NavHashLink smooth to="/citragran-cibubur">
                CitraGran Cibubur
                <hr />
              </NavHashLink>
            </li>
          </ul>
          <button
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send?phone=6281386155890&text=Halo%20Lenny%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(https://marketingciputracibubur.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                "_blank"
              )
            }
            className="wa"
          >
            Contact Us
          </button>
        </div>
        <div className="icons" onClick={toggleMenu}>
          <FaBars className={isOpen ? "bars active" : "bars"} />
          <RiCloseLine className={isOpen ? "close active" : "close"} />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
