import React from "react";
import "./home.scss";
import ScrollToHashElement from "./ScrollToHashElement";
import Hero from "../../component/hero/hero";
import desktopImage from "../../asset/banner.png";
import About from "./section/about/about";
import Fasilitas from "./section/fasilitas/fasilitas";
import Promo from "./section/promo/promo";
import Produk from "./section/produk/produk";
import Cta from "./section/cta/cta";
import mobileImage from "../../asset/heromobile/hero (2).png";

const home = () => {
  return (
    <div className="home">
      <ScrollToHashElement />
      <Hero mobileImage={mobileImage} desktopImage={desktopImage} />
      <About />
      <Fasilitas />
      <Promo />
      <Produk />
      <Cta />
    </div>
  );
};

export default home;
