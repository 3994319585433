import glenview5_1 from "./Tipe 5.webp";
import glenview5_2 from "./gv6.webp";
import glenview8_1 from "./Tipe 8.webp";
import glenview8_2 from "./gv 8.webp";
import glenview10_1 from "./Tipe 10.webp";
import glenview10_2 from "./gv10.webp";

export default {
  glenview5: [glenview5_1, glenview5_2],
  glenview8: [glenview8_1, glenview8_2],
  glenview10: [glenview10_1, glenview10_2],
};
