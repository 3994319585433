import Brunneral101 from "./Brunneral10 (1).png";
import Brunneral102 from "./Brunneral10 (2).png";
import Brunneral103 from "./Brunneral10 (3).png";
import Brunneral104 from "./Brunneral10 (4).png";
import Brunneral105 from "./Brunneral10 (5).png";
import Brunneral106 from "./Brunneral10 (6).png";
import Brunneral107 from "./Brunneral10 (7).png";
import Brunneral108 from "./Brunneral10 (8).png";
import Brunneral109 from "./Brunneral10 (9).png";
import Brunneral1010 from "./Brunneral10 (10).png";
import Brunneral1011 from "./Brunneral10 (11).png";
import Brunneral1012 from "./Brunneral10 (12).png";
import Brunneral1013 from "./Brunneral10 (13).png";
import Brunneral1014 from "./Brunneral10 (14).png";
import Brunneral1015 from "./Brunneral10 (15).png";
import Brunneral1016 from "./Brunneral10 (16).png";
import Brunneral1017 from "./Brunneral10 (17).png";

const brunneral10 = [
  Brunneral101,
  Brunneral102,
  Brunneral103,
  Brunneral104,
  Brunneral105,
  Brunneral106,
  Brunneral107,
  Brunneral108,
  Brunneral109,
  Brunneral1010,
  Brunneral1011,
  Brunneral1012,
  Brunneral1013,
  Brunneral1014,
  Brunneral1015,
  Brunneral1016,
  Brunneral1017,
];

export default brunneral10;
