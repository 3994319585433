import React from "react";
import "./Cardred.scss";

const Cardred = ({ src, pricing, rumah }) => {
  return (
    <div className="card-red">
      <div className="cardlayer">
        <div className="layer" />
        <img src={src} alt="image" className="imgproyek" />
        <button className="card-button">{pricing}</button>
        <div className="card-title">{rumah}</div>
      </div>
    </div>
  );
};

export default Cardred;
