import React from "react";
import "./promoc.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Promoc = ({ judul, details1, details2, whatsappLink, Promoimage }) => {
  const renderDetail = (detail) => {
    const parts = detail.split(" ");
    const iconClass = parts.shift(); // Remove and get the first part as icon class

    // Determine FontAwesome icon based on class name
    let iconComponent = null;
    switch (iconClass) {
      case "faCircleCheck":
        iconComponent = <FontAwesomeIcon icon={faCircleCheck} />;
        break;
      default:
        break;
    }
    return (
      <div className="penawaranpoin">
        {iconComponent && <span>{iconComponent}</span>}
        <span style={{ color: "white" }}>&nbsp;&nbsp;{parts.join(" ")}</span>
      </div>
    );
  };
  return (
    <div className="container-promoc">
      <div className=" container-listpromoc">
        <div className="containercontent">
          <div className="pointpenawaran">
            <div id="promo" className="judul-promoc">
              <h1>{judul}</h1>
              <h2>SPECIAL DISCOUNT CICILAN 3 JUTA/BULAN</h2>
            </div>
            <div className="penawaran">
              <div className="list1">
                {Object.values(details1).map((detail, index) =>
                  renderDetail(detail)
                )}
              </div>
              <div className="list1">
                {Object.values(details2).map((detail, index) =>
                  renderDetail(detail)
                )}
              </div>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <div className="button-wa-promo">
            <button
              onClick={() => window.open(whatsappLink, "_blank")}
              className="whatsapp"
            >
              Lihat Detail <FontAwesomeIcon icon={faWhatsapp} />
            </button>
          </div>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={Promoimage} alt="hardsell" />
        </div>
      </div>
    </div>
  );
};

export default Promoc;
