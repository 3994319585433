import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToTop from "./ScrollToTop.jsx";
import Navbar from "./component/navbar/navbar.jsx";
import Footer from "./component/footer/footer.jsx";
import Citralandcibubur from "./pages/detail/citralandcibubur/citralandcibubur.jsx";
import Citragrandcbd from "./pages/detail/citragrandcbd/citragrandcbd.jsx";
import Citragrancibubur from "./pages/detail/citragrancibubur/citragrancibubur.jsx";

const App = () => {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/citraland-cibubur/" element={<Citralandcibubur />} />
          <Route path="/citragrand-cbd/" element={<Citragrandcbd />} />
          <Route path="/citragran-cibubur/" element={<Citragrancibubur />} />
        </Routes>
        <Footer />
      </Router>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://api.whatsapp.com/send?phone=6281386155890&text=Halo%20Lenny%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(https://marketingciputracibubur.com/citragrand-cibubur)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0"
      >
        <FloatingWhatsApp />
      </a>
    </div>
  );
};

export default App;
