import Fasilitas1 from "./fasilitas (1).png";
import Fasilitas2 from "./fasilitas (2).png";
import Fasilitas3 from "./fasilitas (3).png";
import Fasilitas4 from "./fasilitas (4).png";
import Fasilitas5 from "./fasilitas (5).png";
import Fasilitas6 from "./fasilitas (6).png";

const fas = [
  Fasilitas1,
  Fasilitas2,
  Fasilitas3,
  Fasilitas4,
  Fasilitas5,
  Fasilitas6,
];

export default fas;
