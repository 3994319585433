import Attaleya1 from "./ATTALEYA (1).webp";
import Attaleya2 from "./ATTALEYA (2).webp";
import Attaleya3 from "./ATTALEYA (3).webp";
import Attaleya4 from "./ATTALEYA (4).webp";
import Attaleya5 from "./ATTALEYA (5).webp";
import Attaleya6 from "./ATTALEYA (6).webp";
import Attaleya7 from "./ATTALEYA (7).png";
import Attaleya8 from "./ATTALEYA (8).png";

const attaleya = [
  Attaleya1,
  Attaleya2,
  Attaleya3,
  Attaleya4,
  Attaleya5,
  Attaleya6,
  Attaleya7,
  Attaleya8,
];

export default attaleya;
