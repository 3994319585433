import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faAngleRight, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./cardprodukhome.scss";

const cardprodukhome = ({
  header,
  imgCard,
  paragraf,
  details1,
  details2,
  hook,
  whatsappLink,
  pageLink,
}) => {
  const renderDetail = (detail) => {
    const parts = detail.split(" ");
    const iconClass = parts.shift(); // Remove and get the first part as icon class

    // Determine FontAwesome icon based on class name
    let iconComponent = null;
    switch (iconClass) {
      case "faCircleCheck":
        iconComponent = <FontAwesomeIcon icon={faCircleCheck} />;
        break;
      default:
        break;
    }
    return (
      <div className="penawaranpoin">
        {iconComponent && <span>{iconComponent}</span>}
        <span style={{ color: "black" }}>&nbsp;&nbsp;{parts.join(" ")}</span>
      </div>
    );
  };
  return (
    <div className="cardprodukhome">
      <div className="container-cardprodukhome">
        <div className="header-cardprodukhome">
          <div className="header1">
            <h1>Our Area</h1>
          </div>
          <div className="header2">{header}</div>
        </div>
        <div className="content-cardprodukhome">
          <div className="image-card">
            <img src={imgCard} alt="img-card" />
          </div>
          <div className="content">
            <div className="p1">{paragraf}</div>
            <div className="listpromo">
              <div className="list1">
                {Object.values(details1).map((detail, index) =>
                  renderDetail(detail)
                )}
              </div>
              <div className="list2">
                {Object.values(details2).map((detail, index) =>
                  renderDetail(detail)
                )}
              </div>
            </div>
            <div className="buttonpage">
              <button
                onClick={() => window.open(pageLink, "_blank")}
                className="page"
              >
                Lihat Detail <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </div>
          </div>
        </div>
        <div className="container-hardsell">
          <div className="hardsell">
            <div className="hook">{hook}</div>
            <div className="buttonwa">
              <button
                onClick={() => window.open(whatsappLink, "_blank")}
                className="whatsapp"
              >
                <FontAwesomeIcon icon={faWhatsapp} /> Dapatkan Promo Terbaik
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default cardprodukhome;
