import React from "react";
import "./footer.scss";
import logo from "./logo-b.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocation,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-pik" />
          <h2>Marketing Gallery</h2>
          <h1>Ciputra Group</h1>
        </div>
        <div className="container-deskripsi">
          <div className="judul-alamat">Kontak Kami</div>
          <div className="alamat">
            <p>
              <FontAwesomeIcon icon={faLocationDot} />
              &nbsp;&nbsp; Ciputra World Jakarta 1, Jl. Prof. DR. Satrio Kav 3-5
              DBS Bank Tower Lt. 39, Jakarta 12940 Indonesia
            </p>
          </div>
          <div className="contact">
            <FontAwesomeIcon icon={faPhone} />
            &nbsp;&nbsp; +6281386155890
          </div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">
        © 2024 Ciputra Group. All Rights Reserved.
      </div>
    </div>
  );
};

export default footer;
