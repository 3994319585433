import Montanal81 from "./montanal8 (1).webp";
import Montanal82 from "./montanal8 (2).webp";
import Montanal83 from "./montanal8 (3).webp";
import Montanal84 from "./montanal8 (4).webp";
import Montanal85 from "./montanal8 (5).webp";
import Montanal86 from "./montanal8 (6).webp";
import Montanal87 from "./montanal8 (7).webp";
import Montanal88 from "./montanal8 (8).webp";
import Montanal89 from "./montanal8 (9).webp";
import Montanal810 from "./montanal8 (10).webp";
import Montanal811 from "./montanal8 (11).webp";
import Montanal812 from "./montanal8 (12).webp";

const montanal8 = [
  Montanal81,
  Montanal82,
  Montanal83,
  Montanal84,
  Montanal85,
  Montanal86,
  Montanal87,
  Montanal88,
  Montanal89,
  Montanal810,
  Montanal811,
  Montanal812,
];

export default montanal8;
