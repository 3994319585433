import React from "react";
import "./Cardw.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faLayerGroup,
  faMaximize,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Cardruko = ({ carousel, cardtitle, title, details, whatsappLink }) => {
  const settings = {
    dots: carousel.length > 1,
    arrows: carousel.length > 1,
    infinite: carousel.length > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  };

  return (
    <div className="cardw">
      <Slider {...settings}>
        {carousel.map((image, index) => (
          <img
            className="imgcarousel"
            key={index}
            src={image}
            alt={`Launching ${index + 1}`}
          />
        ))}
      </Slider>
      <div className="card-contentw">
        <div className="cardtitle" alt="Myza Logo">
          {cardtitle}
        </div>
        <div className="title">{title}</div>
        <div className="card-icon">
          <FontAwesomeIcon color="#B0CDF4" icon={faMaximize} />
          <span>&nbsp; : {details.area} &nbsp;</span>
          <FontAwesomeIcon color="#B0CDF4" icon={faBuilding} />
          <span>&nbsp; : {details.buildingSize} &nbsp;</span>
          <FontAwesomeIcon color="#B0CDF4" icon={faLayerGroup} />
          <span>&nbsp; : {details.bedrooms} &nbsp;</span>
          <FontAwesomeIcon color="#B0CDF4" icon={faShower} />
          <span>&nbsp; : {details.bathrooms} &nbsp;</span>
        </div>
        <div className="card-button">
          <button
            className="button"
            onClick={() => window.open(whatsappLink, "_blank")}
          >
            <FontAwesomeIcon icon={faWhatsapp} />
            &nbsp;Whatsapp
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cardruko;
