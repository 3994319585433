import Brunneral81 from "./brunneral8 (1).png";
import Brunneral82 from "./brunneral8 (2).png";
import Brunneral83 from "./brunneral8 (3).png";
import Brunneral84 from "./brunneral8 (4).png";
import Brunneral85 from "./brunneral8 (5).png";
import Brunneral86 from "./brunneral8 (6).png";
import Brunneral87 from "./brunneral8 (7).png";
import Brunneral88 from "./brunneral8 (8).png";
import Brunneral89 from "./brunneral8 (9).png";
import Brunneral810 from "./brunneral8 (10).png";

const brunneral8 = [
  Brunneral81,
  Brunneral82,
  Brunneral83,
  Brunneral84,
  Brunneral85,
  Brunneral86,
  Brunneral87,
  Brunneral88,
  Brunneral89,
  Brunneral810,
];

export default brunneral8;
