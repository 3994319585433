import React from "react";
import "./citralandcibubur.scss";
import Hero from "../../../component/hero/hero";
import desktopImage from "../../../asset/hero/hero-citraland.webp";
import data from "../../../component/aboutd/aboutd.json";
import imgabout from "../../../asset/imgabout/imgabout.js";
import mobileImage from "../../../asset/heromobile/citraland-cibubur (1).png";
import Aboutd from "../../../component/aboutd/aboutd.jsx";
import Promoc from "../../../component/promoc/promoc.jsx";
import prmo from "../../../component/promoc/promoc.json";
import Promoimage from "../../../asset/promoimage/promoimage.js";
import Cardc from "../../../component/Card/Cardc.jsx";
import Cardw from "../../../component/Card/Cardw.jsx";
import datap from "../../../component/Card/data.json";
import montanal5 from "../../../asset/imgproduk/citraland/montana/Montana L5/montanal5.js";
import montanal6 from "../../../asset/imgproduk/citraland/montana/Montana L6/montanal6.js";
import montanal7 from "../../../asset/imgproduk/citraland/montana/Montana L7/montanal7.js";
import montanal8 from "../../../asset/imgproduk/citraland/montana/Montana L8/montanal8.js";
import monterrey1 from "../../../asset/imgproduk/citraland/Monterrey/monterrey1/monterrey1.js";
import monterrey2a from "../../../asset/imgproduk/citraland/Monterrey/monterrey2a/monterrey2a.js";
import monterrey2b from "../../../asset/imgproduk/citraland/Monterrey/monterrey2b/monterrey2b.js";
import aphandra from "../../../asset/imgproduk/citraland/monteverde/aphandra/aphandra.js";
import areca from "../../../asset/imgproduk/citraland/monteverde/areca/areca.js";
import attaleya from "../../../asset/imgproduk/citraland/monteverde/attaleya/attaleya.js";
import caryota from "../../../asset/imgproduk/citraland/monteverde/caryota/caryota.js";
import livistona from "../../../asset/imgproduk/citraland/monteverde/livistona/livistona.js";
import newattaleya from "../../../asset/imgproduk/citraland/monteverde/newattaleya/newattaleya.js";
import Surrounding from "../../../component/surrounding/Surroundingcl.jsx";
import Lokasicl from "../../../component/lokasi/lokasicl.jsx";
import ScrollToHashElement from "../../home/ScrollToHashElement.js";
const imageMapmtn = {
  montanal5,
  montanal6,
  montanal7,
  montanal8,
};
const imageMapmtr = {
  monterrey1,
  monterrey2a,
  monterrey2b,
};
const imageMapmtv = {
  aphandra,
  areca,
  attaleya,
  newattaleya,
  caryota,
  livistona,
};

const citralandcibubur = () => {
  return (
    <div className="citralandcibubur">
      <ScrollToHashElement />
      <Hero mobileImage={mobileImage} desktopImage={desktopImage} />
      <div id="about" className="about-cl">
        {data.aboutcl.map((card, index) => {
          // Determine the logo dynamically
          const fotopromo = imgabout[card.imgabout];
          return (
            <Aboutd
              key={index}
              imgabout={fotopromo}
              title={card.title}
              desk1={card.desk1}
              desk2={card.desk2}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div id="promo" className="promo-detail">
        {prmo.prmoCl.map((card, index) => {
          // Determine the logo dynamically
          const fotopromo = Promoimage[card.Promoimage];
          return (
            <Promoc
              key={index}
              Promoimage={fotopromo}
              judul={card.judul}
              details1={card.details1}
              details2={card.details2}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div id="montana" className="container-produkmtn">
        <div className="header">
          <div className="h1">Our Home</div>
          <div className="h2">MONTANA</div>
        </div>
        <div className="montana">
          {" "}
          {datap.montanar.map((card, index) => {
            // Determine the logo dynamically

            return (
              <Cardc
                key={index}
                carousel={imageMapmtn[card.imagesKey]}
                cardtitle={card.cardtitle} // Dynamically import logo images
                title={card.title}
                details={card.details}
                whatsappLink={card.whatsappLink}
              />
            );
          })}
        </div>
      </div>
      <div id="monterrey" className="container-produkmtr">
        <div className="header">
          <div className="h1">Our Home</div>
          <div className="h2">MONTERREY</div>
        </div>
        <div className="montana">
          {" "}
          {datap.monterreyr.map((card, index) => {
            // Determine the logo dynamically

            return (
              <Cardw
                key={index}
                carousel={imageMapmtr[card.imagesKey]}
                cardtitle={card.cardtitle} // Dynamically import logo images
                title={card.title}
                details={card.details}
                whatsappLink={card.whatsappLink}
              />
            );
          })}
        </div>
      </div>
      <div id="monteverde" className="container-produkmtn">
        <div className="header">
          <div className="h1">Our Home</div>
          <div className="h2">MONTEVERDE</div>
        </div>
        <div className="montana">
          {" "}
          {datap.monteverder.map((card, index) => {
            // Determine the logo dynamically

            return (
              <Cardc
                key={index}
                carousel={imageMapmtv[card.imagesKey]}
                cardtitle={card.cardtitle} // Dynamically import logo images
                title={card.title}
                details={card.details}
                whatsappLink={card.whatsappLink}
              />
            );
          })}
        </div>
      </div>
      <div className="surrounding">
        <Surrounding />
      </div>
      <div className="lokasi">
        <Lokasicl />
      </div>
    </div>
  );
};

export default citralandcibubur;
