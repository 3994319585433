import React from "react";
import "./promo.scss";
import Promoimage from "../../../../asset/promo.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Promo = () => {
  const wapromo = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send?phone=6281386155890&text=Halo%20Lenny%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(https://marketingciputracibubur.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="container-promo" id="promo">
      <div id="promo" className=" container-listpromo">
        <div className="containercontent">
          <div className="pointpenawaran">
            <div id="promo" className="judul-promo">
              <h1>Promo Keseluruhan Ciputra Cibubur</h1>
              <h2>SPECIAL DISCOUNT CICILAN 3 JUTA/BULAN</h2>
            </div>
            <div className="penawaran">
              <div className="list1">
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>&nbsp;&nbsp;Tanpa DP</span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>
                    &nbsp;&nbsp;Special Discount
                  </span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>
                    &nbsp;&nbsp;Free Biaya AJB
                  </span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>&nbsp;&nbsp;Free BPHTB</span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>
                    &nbsp;&nbsp;Free Biaya KPR
                  </span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>
                    &nbsp;&nbsp;Free IPL 12 Bulan
                  </span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>
                    &nbsp;&nbsp;Free Smart Home System
                  </span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>
                    &nbsp;&nbsp;Free Smart Door Lock
                  </span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>&nbsp;&nbsp;Free CCTV</span>
                </div>
              </div>
              <div className="list1">
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>&nbsp;&nbsp;Free AC</span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>
                    &nbsp;&nbsp;Free Canopy
                  </span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>
                    &nbsp;&nbsp;Free Assuransi
                  </span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>
                    &nbsp;&nbsp;Free Biaya Notaris
                  </span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>&nbsp;&nbsp;Free SHGB</span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>
                    &nbsp;&nbsp;Free Motor Listrik*
                  </span>
                </div>
                <div className="penawaranpoin">
                  <FontAwesomeIcon icon={faCircleCheck} />
                  <span style={{ color: "white" }}>
                    &nbsp;&nbsp;Free Voucher
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <div className="button-wa-promo">
            <button onClick={wapromo} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Dapatkan Promo Terbaik
            </button>
          </div>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={Promoimage} alt="hardsell" />
        </div>
      </div>
    </div>
  );
};

export default Promo;
