import React from "react";
import "./aboutd.scss";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const aboutd = ({ title, desk1, desk2, whatsappLink, imgabout }) => {
  return (
    <div className="aboutd">
      <div id="about" className="container-aboutd">
        <div className="desk">
          <div className="title">{title}</div>
          <div className="p">
            {desk1} <br />
            <br /> {desk2}
          </div>
          <div className="button">
            <button
              onClick={() => window.open(whatsappLink, "_blank")}
              className="whatsapp"
            >
              <FontAwesomeIcon icon={faWhatsapp} /> Dapatkan Promo Terbaik
            </button>
          </div>
        </div>
        <div className="image">
          <img src={imgabout} alt="image-about" />
        </div>
      </div>
    </div>
  );
};

export default aboutd;
