import React from "react";
import "./citragrandcbd.scss";
import desktopImage from "../../../asset/hero/hero-cbd.webp";
import Hero from "../../../component/hero/hero";
import mobileImage from "../../../asset/heromobile/citragrand-cbd (1).png";
import data from "../../../component/aboutd/aboutd.json";
import imgabout from "../../../asset/imgabout/imgabout.js";
import Aboutd from "../../../component/aboutd/aboutd.jsx";
import Promoc from "../../../component/promoc/promoc.jsx";
import prmo from "../../../component/promoc/promoc.json";
import Promoimage from "../../../asset/promoimage/promoimage.js";
import Cardc from "../../../component/Card/Cardc.jsx";
import datap from "../../../component/Card/data.json";
import Lokasicbd from "../../../component/lokasi/lokasicbd.jsx";
import brunneral6 from "../../../asset/imgproduk/citragrandcbd/brunnera/Brunnera L6/brunneral6.js";
import brunneral5 from "../../../asset/imgproduk/citragrandcbd/brunnera/Brunnera L5/brunneral5.js";
import brunneral8 from "../../../asset/imgproduk/citragrandcbd/brunnera/Brunnera L8/brunneral8.js";
import brunneral10 from "../../../asset/imgproduk/citragrandcbd/brunnera/Brunnera L10/brunneral10.js";
import brunneral7 from "../../../asset/imgproduk/citragrandcbd/brunnera/Brunnera L7/brunneral7.js";
import Surrounding from "../../../component/surrounding/Surroundingcbd.jsx";
import proyek1 from "../../../asset/proyeksebelum/card-area.png";
import proyek2 from "../../../asset/proyeksebelum/card-area (1).png";
import proyek3 from "../../../asset/proyeksebelum/card-area (2).png";
import Cardred from "../../../component/cardred/Cardred.jsx";
import ScrollToHashElement from "../../home/ScrollToHashElement.js";
const imageMap = {
  brunneral5,
  brunneral6,
  brunneral7,
  brunneral8,
  brunneral10,
};

const imageProyek = {
  proyek1,
  proyek2,
  proyek3,
};

const citragrandcbd = () => {
  return (
    <div className="citragrancbd">
      <ScrollToHashElement />
      <Hero mobileImage={mobileImage} desktopImage={desktopImage} />
      <div id="about" className="about-cbd">
        {data.aboutcbd.map((card, index) => {
          // Determine the logo dynamically
          const fotopromo = imgabout[card.imgabout];
          return (
            <Aboutd
              key={index}
              imgabout={fotopromo}
              title={card.title}
              desk1={card.desk1}
              desk2={card.desk2}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div id="promo" className="promo-detail">
        {prmo.prmocbd.map((card, index) => {
          // Determine the logo dynamically
          const fotopromo = Promoimage[card.Promoimage];
          return (
            <Promoc
              key={index}
              Promoimage={fotopromo}
              judul={card.judul}
              details1={card.details1}
              details2={card.details2}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div id="brunnera" className="container-produk">
        <div className="header">
          <div className="h1">Our Home</div>
          <div className="h2">BRUNNERA</div>
        </div>
        <div className="montana">
          {" "}
          {datap.brunnerar.map((card, index) => {
            // Determine the logo dynamically

            return (
              <Cardc
                key={index}
                carousel={imageMap[card.imagesKey]}
                cardtitle={card.cardtitle} // Dynamically import logo images
                title={card.title}
                details={card.details}
                whatsappLink={card.whatsappLink}
              />
            );
          })}
        </div>
        <div className="proyek-sebelum">
          <div className="header-proyek">
            <div className="h2">PROYEK SEBELUMNYA</div>
          </div>
          <div className="card-proyek">
            {datap.proyeksebelum.map((card, index) => {
              // Determine the logo dynamically

              return (
                <Cardred
                  key={index}
                  src={imageProyek[card.imagesKey]}
                  pricing={card.pricing}
                  rumah={card.rumah}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="surrounding">
        <Surrounding />
      </div>
      <div className="lokasi">
        <Lokasicbd />
      </div>
    </div>
  );
};

export default citragrandcbd;
