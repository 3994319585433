import React from "react";
import "./cta.scss";

const cta = () => {
  return (
    <div className="cta">
      <div className="container-cta">
        <div className="header-cta">
          Siap untuk Menemukan Hunian Impian Anda? Konsultasikan Bersama Kami!
        </div>
        <div className="desk-cta">
          Hubungi kami sekarang! Tim ahli kami siap membantu Anda menemukan
          rumah yang sesuai dengan kebutuhan dan impian Anda. Klik tombol di
          bawah ini untuk langsung terhubung melalui WhatsApp dan wujudkan
          impian hunian Anda bersama kami!
        </div>
      </div>
      <div className="buttoncta">
        <button
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send?phone=6281386155890&text=Halo%20Lenny%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(https://marketingciputracibubur.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
              "_blank"
            )
          }
          className="whatsapp"
        >
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default cta;
