import React from "react";
import "./produk.scss";
import Cardprodukhome from "../../../../component/cardprodukhome/cardprodukhome";
import data from "../../../../component/cardprodukhome/data.json";
import imgCard from "../../../../asset/imgcard/imgCard.js";
import Cardprodukhome1 from "../../../../component/cardprodukhome/cardprodukhome1.jsx";
import Card from "../../../../component/Card/Cardruko.jsx";
import ruko from "../../../../component/Card/data.json";
import marquete from "../../../../asset/imgproduk/marquete/marquete.js";
import avenue from "../../../../asset/imgproduk/avenue/avenue.js";
const imageMap = {
  marquete,
  avenue,
};

const produk = () => {
  return (
    <div className="produk">
      <div className="container-produk">
        <div className="card-cl">
          {data.cardcl.map((card, index) => {
            // Determine the logo dynamically
            const fotopromo = imgCard[card.imgCard];
            return (
              <Cardprodukhome
                key={index}
                imgCard={fotopromo}
                header={card.header}
                paragraf={card.paragraf}
                hook={card.hook}
                details1={card.details1}
                details2={card.details2}
                pageLink={card.pageLink}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
        <div className="card-cbd">
          {data.cardcbd.map((card, index) => {
            // Determine the logo dynamically
            const fotopromo = imgCard[card.imgCard];
            return (
              <Cardprodukhome1
                key={index}
                imgCard={fotopromo}
                header={card.header}
                paragraf={card.paragraf}
                hook={card.hook}
                details1={card.details1}
                details2={card.details2}
                pageLink={card.pageLink}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
        <div className="card-cg">
          {data.cardcg.map((card, index) => {
            // Determine the logo dynamically
            const fotopromo = imgCard[card.imgCard];
            return (
              <Cardprodukhome
                key={index}
                imgCard={fotopromo}
                header={card.header}
                paragraf={card.paragraf}
                hook={card.hook}
                details1={card.details1}
                details2={card.details2}
                pageLink={card.pageLink}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
        <div className="card-ruko">
          <div className="header">OUR COMMERCIAL AREA (RUKO)</div>
          <div className="cardproduk" id="ruko">
            {" "}
            {ruko.cards.map((card, index) => {
              // Determine the logo dynamically

              return (
                <Card
                  key={index}
                  carousel={imageMap[card.imagesKey]}
                  cardtitle={card.cardtitle} // Dynamically import logo images
                  title={card.title}
                  details={card.details}
                  whatsappLink={card.whatsappLink}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default produk;
