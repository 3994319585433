import React from "react";
import "./about.scss";
import { BsChatRightHeartFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const about = () => {
  return (
    <div className="about">
      <div className="container-about">
        <div className="headline">
          <div className="head1">Ciputra Group</div>
          <div className="head2">LIVE, PLAY AND WORK IN ONE AREA</div>
        </div>
        <div className="deskripsi">
          <div className="desk">
            Ciputra Group merupakan grup usaha yang telah berkiprah selama lebih
            dari 40 tahun, didirikan oleh Almarhum Dr. (HC) Ir. Ciputra beserta
            keluarga. Almarhum Pak Ciputra juga merupakan tokoh properti
            Indonesia yang mendirikan REI dan membidani lahirnya sejumlah
            korporasi besar antara lain Grup Jaya dan Grup Metropolitan.
          </div>
        </div>
        <div className="listkawasan">
          <div className="list1">
            <ul>
              <li>
                <BsChatRightHeartFill /> Club House
              </li>
              <li>
                <BsChatRightHeartFill /> Swimming Pool
              </li>
              <li>
                <BsChatRightHeartFill /> Jogging Track
              </li>
              <li>
                <BsChatRightHeartFill /> Civic Center
              </li>
              <li>
                <BsChatRightHeartFill /> Playground
              </li>
            </ul>
          </div>
          <div className="list2">
            <ul>
              <li>
                <BsChatRightHeartFill /> Mall Ciputra
              </li>
              <li>
                <BsChatRightHeartFill /> Ciputra Hospital
              </li>
              <li>
                <BsChatRightHeartFill /> Culinary Center
              </li>
              <li>
                <BsChatRightHeartFill /> Tol Cibubur dan LRT Cibubur
              </li>
              <li>
                <BsChatRightHeartFill /> Shop House
              </li>
            </ul>
          </div>
        </div>
        <div className="button">
          <button
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send?phone=6281386155890&text=Halo%20Lenny%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(https://marketingciputracibubur.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                "_blank"
              )
            }
            className="whatsapp"
          >
            <FontAwesomeIcon icon={faWhatsapp} /> Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default about;
