import Monterrey2b1 from "./monterrey2b (1).webp";
import Monterrey2b2 from "./monterrey2b (2).webp";
import Monterrey2b3 from "./monterrey2b (3).webp";
import Monterrey2b4 from "./monterrey2b (4).webp";
import Monterrey2b5 from "./monterrey2b (5).webp";
import Monterrey2b6 from "./monterrey2b (6).webp";
import Monterrey2b7 from "./monterrey2b (7).webp";
import Monterrey2b8 from "./monterrey2b (8).webp";

const monterrey2b = [
  Monterrey2b1,
  Monterrey2b2,
  Monterrey2b3,
  Monterrey2b4,
  Monterrey2b5,
  Monterrey2b6,
  Monterrey2b7,
  Monterrey2b8,
];

export default monterrey2b;
