import React from "react";
import "./Surrounding.scss";
import clubhouse from "./fasilitascbd/Frame 116 (1).png";
import joggingtrack from "./fasilitascbd/Frame 117 (1).png";
import civiccenter from "./fasilitascbd/Frame 118 (1).png";
import breeze from "./fasilitascbd/Frame 119.png";
import qbig from "./fasilitascbd/Frame 120.png";
import eka from "./fasilitascbd/Frame 121.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Surrounding = () => {
  const slides = [
    { src: clubhouse, des: "Premium Apartments" },
    { src: joggingtrack, des: "Residential Area" },
    { src: civiccenter, des: "Club House" },
    { src: breeze, des: "Universitas Ciputra" },
    { src: qbig, des: "Lifestyle Center" },
    { src: eka, des: "Culinary Center" },
  ];
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="surroundingcontainer">
      <div className="title">FASILITAS PREMIUM</div>
      <div className="content">
        {slides.map((slide, index) => (
          <div className="center" key={index}>
            <img className="imgcontent" src={slide.src} alt="Surrounding" />
            <div className="imgdes">
              <span>{slide.des}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="content1">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div className="center" key={index}>
              <img className="imgcontent" src={slide.src} alt="Surrounding" />
              <div className="imgdes">
                <span>{slide.des}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Surrounding;
