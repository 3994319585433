import React from "react";
import "./citragrancibubur.scss";
import Hero from "../../../component/hero/hero";
import desktopImage from "../../../asset/hero/hero-citragran.webp";
import mobileImage from "../../../asset/heromobile/citragran-cibubur (1).png";
import data from "../../../component/aboutd/aboutd.json";
import imgabout from "../../../asset/imgabout/imgabout.js";
import Aboutd from "../../../component/aboutd/aboutd.jsx";
import Promoc from "../../../component/promoc/promoc.jsx";
import prmo from "../../../component/promoc/promoc.json";
import Promoimage from "../../../asset/promoimage/promoimage.js";
import Glenview from "../../../asset/imgproduk/Glenview/index.js";
import Cardc from "../../../component/Card/Cardc.jsx";
import datap from "../../../component/Card/data.json";
import Surrounding from "../../../component/surrounding/Surroundingcbd.jsx";
import Lokasicl from "../../../component/lokasi/lokasicl.jsx";
import ScrollToHashElement from "../../home/ScrollToHashElement.js";
const citragrancibubur = () => {
  const imageMap = {
    ...Glenview,
  };

  return (
    <div className="citragrancibubur">
      <ScrollToHashElement />
      <Hero mobileImage={mobileImage} desktopImage={desktopImage} />
      <div id="about" className="about-cg">
        {data.aboutcg.map((card, index) => {
          // Determine the logo dynamically
          const fotopromo = imgabout[card.imgabout];
          return (
            <Aboutd
              key={index}
              imgabout={fotopromo}
              title={card.title}
              desk1={card.desk1}
              desk2={card.desk2}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div id="promo" className="promo-detail">
        {prmo.prmocg.map((card, index) => {
          // Determine the logo dynamically
          const fotopromo = Promoimage[card.Promoimage];
          return (
            <Promoc
              key={index}
              Promoimage={fotopromo}
              judul={card.judul}
              details1={card.details1}
              details2={card.details2}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div id="glenview" className="container-produk">
        <div className="header">
          <div className="h1">Our Home</div>
          <div className="h2">GLENVIEW</div>
        </div>
        <div className="montana">
          {" "}
          {datap.glenview.map((card, index) => {
            // Determine the logo dynamically

            return (
              <Cardc
                key={index}
                carousel={imageMap[card.imagesKey]}
                cardtitle={card.cardtitle} // Dynamically import logo images
                title={card.title}
                details={card.details}
                whatsappLink={card.whatsappLink}
              />
            );
          })}
        </div>
      </div>
      <div className="surrounding">
        <Surrounding />
      </div>
      <div className="lokasi">
        <Lokasicl />
      </div>
    </div>
  );
};

export default citragrancibubur;
