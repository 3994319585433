import Caryota1 from "./CARYOTA (1).webp";
import Caryota2 from "./CARYOTA (2).webp";
import Caryota3 from "./CARYOTA (3).webp";
import Caryota4 from "./CARYOTA (4).webp";
import Caryota5 from "./CARYOTA (5).webp";
import Caryota6 from "./CARYOTA (6).webp";
import newCaryota1 from "./new-caryota (1).webp";
import newCaryota2 from "./new-caryota (2).webp";
import newCaryota3 from "./new-caryota (3).webp";
import newCaryota4 from "./new-caryota (4).webp";
import newCaryota5 from "./new-caryota (5).webp";
import newCaryota6 from "./new-caryota (6).webp";
import newCaryota7 from "./new-caryota (7).webp";
import newCaryota8 from "./new-caryota (8).webp";
import newCaryota9 from "./new-caryota (9).webp";
import newCaryota10 from "./new-caryota (10).webp";
import newCaryota11 from "./new-caryota (11).webp";

const caryota = [
  newCaryota1,
  newCaryota2,
  newCaryota3,
  newCaryota4,
  newCaryota5,
  newCaryota6,
  newCaryota7,
  newCaryota8,
  newCaryota9,
  newCaryota10,
  newCaryota11,
  Caryota1,
  Caryota2,
  Caryota3,
  Caryota4,
  Caryota5,
  Caryota6,
];

export default caryota;
